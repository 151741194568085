@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body{
  font-family: 'Montserrat', sans-serif;
}

.center{
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
  
}

/* Temel stiller */
.App {
  text-align: center;
  min-height: 100vh;
  overflow: hidden;
}